export const relocateLandCoveringVisuals = [
  {
    name: "Demo",
    markdown: `![Relocate land covering GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const relocateLandCovering = {
  order: 28,
  name: "Relocate land coverings",
  keyword: "move",
  subcategory: "Properties",
  markdown: `# Relocate land coverings

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} includes multiple {% inlineRouterLink articleId="add-a-fee-parcel" %}fee parcels{% /inlineRouterLink %}, it may become necessary to move a {% inlineRouterLink articleId="add-a-land-covering" %}land covering{% /inlineRouterLink %} to a different parcel (or parcels).

  {% callout type="tip" %}
  **Tip:** A land covering can span multiple fee parcels. Conversely, multiple land coverings can sit atop one fee parcel.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="relocateLandCovering" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcels{% /inlineRouterLink %} to move the land covering to.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The land covering moves onto the selected parcel(s).
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$relocateLandCoveringVisuals /%}
  `,
};
